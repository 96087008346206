import { TrackingWindow } from './types';

export type GTMSettings = {
  environment: string;
  scope: TrackingWindow;
  optedOutExternalTracking?: boolean;
  enablePartytown?: boolean;
};

export const OPT_OUT_DATALAYER_VAR = 'user_opted_out_external_tracking';

export const initializeGTM = ({
  scope,
  environment,
  optedOutExternalTracking,
  enablePartytown,
}: GTMSettings) => {
  scope.dataLayer ??= [];

  scope.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

  if (optedOutExternalTracking) {
    scope.dataLayer.push({ [OPT_OUT_DATALAYER_VAR]: true });
  }

  let preview_env = '';
  if (environment === 'development') {
    preview_env = '&gtm_auth=DoN0WSxjuUkImaph8PYXmA&gtm_preview=env-233';
  } else if (environment === 'staging') {
    preview_env = '&gtm_auth=VrQuCDuWXkLlTwNHJYEKTg&gtm_preview=env-232';
  }

  const gtm = document.createElement('script');
  gtm.src = `https://www.googletagmanager.com/gtm.js?id=GTM-KTLK85W${preview_env}`;
  if (enablePartytown) {
    gtm.type = 'text/partytown';
  } else {
    gtm.async = true;
  }
  document.getElementsByTagName('head')[0].appendChild(gtm);
  if (enablePartytown) {
    // Let Partytown know that a new script has been added.
    window.dispatchEvent(new CustomEvent('ptupdate'));
  }
};
