export type NullableList = (string | null)[] | null | undefined;

export const shouldShowForPage = (
  path: string,
  limitToPages: NullableList,
  excludedPages: NullableList
): boolean => {
  if (!limitToPages && !excludedPages) {
    return true;
  }

  if (limitToPages && limitToPages.includes(path)) {
    return true;
  }

  if (excludedPages && !excludedPages.includes(path)) {
    return true;
  }

  return false;
};
