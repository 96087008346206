import React, { useContext, useMemo } from 'react';

const SKIP_BRANDED_BANNER_PROP_NAME = '__SKIP_BRANDED_BANNER__';

export const exportSkipBrandedBannerAsProps = (skipBrandedBanner: boolean) => ({
  [SKIP_BRANDED_BANNER_PROP_NAME]: skipBrandedBanner,
});

export type PagePropsWithSkipBrandedBanner = {
  [SKIP_BRANDED_BANNER_PROP_NAME]: boolean;
};

export const useSkipBrandedBannerFromServer = (
  pageProps: PagePropsWithSkipBrandedBanner
) => useMemo(() => pageProps[SKIP_BRANDED_BANNER_PROP_NAME], [pageProps]);

export const SkipBrandedBannerContext = React.createContext<boolean>(false);

export const useSkipBrandedBannerContext = () =>
  useContext(SkipBrandedBannerContext);
