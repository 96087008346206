import { Badge } from '@codecademy/gamut';

import { AppHeaderLinkItem } from '../../AppHeader/shared';

export const careerPaths: AppHeaderLinkItem[] = [
  {
    id: 'full-stack-engineer-career-path',
    href: '/learn/paths/full-stack-engineer-career-path',
    trackingTarget: 'topnav_catalog_career_full_stack_engineer',
    text: 'Full-Stack Engineer',
    type: 'link',
  },
  {
    id: 'back-end-engineer-career-path',
    href: '/learn/paths/back-end-engineer-career-path',
    trackingTarget: 'topnav_catalog_career_back-end-engineer',
    text: 'Back-End Engineer',
    type: 'link',
  },
  {
    id: 'ios-developer',
    href: '/learn/paths/ios-developer',
    trackingTarget: 'topnav_catalog_career_ios-developer',
    text: 'iOS Developer',
    type: 'link',
  },
  {
    id: 'front-end-engineer-career-path',
    href: '/learn/paths/front-end-engineer-career-path',
    trackingTarget: 'topnav_catalog_career_front-end-engineer',
    text: 'Front-End Engineer',
    type: 'link',
  },
  {
    id: 'computer-science',
    href: '/learn/paths/computer-science',
    trackingTarget: 'topnav_catalog_career_computer-science',
    text: 'Computer science',
    type: 'link',
  },
  {
    id: 'data-science',
    href: '/pages/data-science-career-specializations',
    trackingTarget: 'topnav_catalog_career_data_science',
    text: 'Data Scientist',
    type: 'link',
  },
];

export const topLanguages: AppHeaderLinkItem[] = [
  {
    id: 'python',
    href: '/catalog/language/python',
    trackingTarget: 'topnav_catalog_skill_python',
    text: 'Python',
    type: 'link',
  },
  {
    id: 'javascript',
    href: '/catalog/language/javascript',
    trackingTarget: 'topnav_catalog_skill_javascript',
    text: 'JavaScript',
    type: 'link',
  },
  {
    id: 'html-css',
    href: '/catalog/language/html-css',
    trackingTarget: 'topnav_catalog_skill_html-css',
    text: 'HTML & CSS',
    type: 'link',
  },
  {
    id: 'sql',
    href: '/catalog/language/sql',
    trackingTarget: 'topnav_catalog_skill_sql',
    text: 'SQL',
    type: 'link',
  },
  {
    id: 'java',
    href: '/catalog/language/java',
    trackingTarget: 'topnav_catalog_skill_java',
    text: 'Java',
    type: 'link',
  },
  {
    id: 'c-plus-plus',
    href: '/catalog/language/c-plus-plus',
    trackingTarget: 'topnav_catalog_skill_c-plus-plus',
    text: 'C++',
    type: 'link',
  },
  {
    id: 'c-sharp',
    href: '/catalog/language/c-sharp',
    trackingTarget: 'topnav_catalog_skill_c-sharp',
    text: 'C#',
    type: 'link',
  },
  {
    id: 'c',
    href: '/catalog/language/c',
    trackingTarget: 'topnav_catalog_skill_c',
    text: 'C',
    type: 'link',
  },
  {
    id: 'php',
    href: '/catalog/language/php',
    trackingTarget: 'topnav_catalog_skill_php',
    text: 'PHP',
    type: 'link',
  },
  {
    id: 'r',
    href: '/catalog/language/r',
    trackingTarget: 'topnav_catalog_skill_r',
    text: 'R',
    type: 'link',
  },
  {
    id: 'it',
    href: '/catalog/subject/information-technology',
    trackingTarget: 'topnav_catalog_skill_it',
    text: 'IT',
    type: 'link',
    badge: (
      <Badge variant="accent" size="sm">
        New
      </Badge>
    ),
  },
  {
    id: 'cybersecurity',
    href: '/catalog/subject/cybersecurity',
    trackingTarget: 'topnav_catalog_skill_cybersecurity',
    text: 'Cybersecurity',
    type: 'link',
  },
];

export const getTopSubjects = (): AppHeaderLinkItem[] => {
  return [
    {
      id: 'cloud-computing',
      href: '/catalog/subject/cloud-computing',
      trackingTarget: 'topnav_catalog_skill_cloud-computing',
      text: 'Cloud computing',
      type: 'link',
    },
    {
      id: 'artificial-intelligence',
      href: '/catalog/subject/artificial-intelligence',
      trackingTarget: 'topnav_catalog_skill_ai',
      text: 'AI',
      type: 'link',
    },
    {
      id: 'web-development',
      href: '/catalog/subject/web-development',
      trackingTarget: 'topnav_catalog_skill_web_development',
      text: 'Web development',
      type: 'link',
    },
    {
      id: 'data-science',
      href: '/catalog/subject/data-science',
      trackingTarget: 'topnav_catalog_skill_data-science',
      text: 'Data science',
      type: 'link',
    },
    {
      id: 'web-design',
      href: '/catalog/subject/web-design',
      trackingTarget: 'topnav_catalog_skill_web-design',
      text: 'Web design',
      type: 'link',
    },
    {
      id: 'professional-skills',
      href: '/catalog/subject/professional-skills',
      trackingTarget: 'topnav_catalog_skill_professional-skills',
      text: 'Professional skills',
      type: 'link',
    },
  ];
};
