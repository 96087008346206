import { BrandedBannerQuery } from '@mono/data/codegen/contentful';
import { BrandedBannerUserQuery } from '@mono/data/codegen/monolith';

export enum BannerAudienceType {
  Anonymous = 'Show to Anonymous Users',
  Free = 'Show to Free Users',
  Trial = 'Show to Trial Users',
  Pro = 'Show to Pro Users',
  Admin = 'Show to Admins',
}

export type NullableBanner = NonNullable<
  BrandedBannerQuery['brandedBannerCollection']
>['items'][number];

export type Banner = NonNullable<NullableBanner>;

export type BrandedBannerUser = BrandedBannerUserQuery['me'];

export const isNonNullBanner = (banner: NullableBanner): banner is Banner =>
  !!banner;
