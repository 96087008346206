import { initializeGTM } from './gtm';
import { initializeOneTrust } from './onetrust';
import { initializePartytown } from './partytown';
import { TrackingWindow } from './types';

export type TrackingIntegrationsSettings = {
  /**
   * Current environment.
   */
  environment: string;

  /**
   * Global scope (often the window) where globals such as analytics are stored.
   */
  scope: TrackingWindow;

  /**
   * Whether user has opted out or is excluded from external tracking
   */
  optedOutExternalTracking?: boolean;

  /**
   * id for the one trust script being used, defaults to the script for `www.codecademy.com`
   */
  oneTrustScript?: string;

  /**
   * Use Partytown to load 3rd party scripts in a worker.
   */
  enablePartytown?: boolean;
};

let init = false;

/**
 * @see README.md for details and usage.
 */
export const initializeTrackingIntegrations = async ({
  environment,
  scope,
  optedOutExternalTracking,
  oneTrustScript,
  enablePartytown,
}: TrackingIntegrationsSettings) => {
  if (init) return; // Prevent multiple initializations
  init = true;

  if (enablePartytown) {
    initializePartytown();
  } else {
    // Wait to allow any other post-hydration logic to run first
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  // Load in OneTrust's banner and wait for its `OptanonWrapper` callback
  await initializeOneTrust({ scope, environment, scriptId: oneTrustScript });

  // Load GTM
  initializeGTM({
    scope,
    environment,
    optedOutExternalTracking,
    enablePartytown,
  });
};
