import { ContentContainerEnum } from '@mono/data/codegen/monolith';
import { CAREER_JOURNEY_TO_CAREER_PATH_MAP } from '@mono/data/curriculum-cards';
import { getUrl, slugOrBlank } from '@mono/util/url';

import { ContentItem } from '~/components/ContainerPages/types';
import {
  ProjectHubCategoryType,
  ProjectSubcategoryType,
} from '~/components/ProjectHub/types';

/*
Note: please see libs/util/url/src/urlPaths.ts
as a good place to add any new url helpers so that
they can be shared across the whole monorepo as a single source of truth.
*/
import { BusinessCheckoutPlanTypeEnum } from '../components/Business/Checkout/types';

export const getBaseUrl = () => process.env.NEXT_PUBLIC_BASE_PATH;

export const getAboutCareersPath = () => `/about/careers`;

export const getJobPath = (id: number) => `/about/job/${id}`;

export const getBusinessCheckoutSuccessPath = (
  planType: BusinessCheckoutPlanTypeEnum
) => {
  return `/business/checkout/success?plan_type=${planType}`;
};

// this is a monolith REST endpoint, so locally you'll need a local nginx config for this to work cross-system
// see: https://gist.github.com/ahmed-abdallah/6d90db9be3f260e79dc137423f3b310c
export const getPayPath = () => '/payments/pay';
export const getBusinessPayPath = () => '/business/direct_purchase';
export const getBusinessUpgradePath = (planId: string) =>
  `/business/plans/${planId}/checkout/convert_trial`;

export const getWhatsappCheckoutCampaignPath = () =>
  '/whatsapp/checkout_campaign';

export const getWhatsappBootcampCheckoutCampaignPath = () =>
  '/whatsapp/bootcamp_checkout_campaign';

export const getWhatsappBootcampCTACampaignPath = () =>
  '/whatsapp/bootcamp_cta_campaign';

// Stripe monolith APIs
export const getStripePaymentIntent = () => '/payments/stripe/payment_method';
export const getStripePaymentCallback = () => '/payments/stripe/callback';

// One-time payment invoice APIs
export const getOnetimePayInvoicePath = (invoiceId: string) =>
  `/payments/onetime/pay/${invoiceId}`;

// Charge APIs
export const getChargeInitPath = () => '/payments/charge/init';
export const getChargeUpdatePath = () => '/payments/charge/update';

export interface BillingPathParams {
  showModal?: boolean;
  changePlan?: boolean;
  redirectUrl?: string;
  renewedPlan?: string;
}

export const getBillingPath = (params?: BillingPathParams) => {
  const billingPath = getUrl('/account/billing', {
    showModal: params?.showModal,
    changePlan: params?.changePlan,
    redirect_url: params?.redirectUrl,
    renewedPlan: params?.renewedPlan,
  });

  return billingPath;
};

export const getCatalogBasePath = () => '/catalog';

export const getCatalogPath = ({
  categoryType,
  category,
}: {
  categoryType: string;
  category?: string;
}) => `${getCatalogBasePath()}/${categoryType}${slugOrBlank(category)}`;

export const getProjectHubHomePath = () => '/projects';

export const getProjectHubCategoryPath = ({
  categoryType,
  categorySlug,
}: {
  categoryType: ProjectHubCategoryType;
  categorySlug: string;
}) => `${getProjectHubHomePath()}/${categoryType}/${categorySlug}`;

export const getProjectHubSubcategoryPath = ({
  categoryType,
  categorySlug,
  projectType,
}: {
  categoryType: ProjectHubCategoryType;
  categorySlug: string;
  projectType: ProjectSubcategoryType;
}) =>
  `${getProjectHubHomePath()}/${categoryType}/${categorySlug}/${projectType}`;

export const getProjectPath = ({
  projectType,
  projectSlug,
}: {
  projectType: ProjectSubcategoryType;
  projectSlug: string;
}) => `${getProjectHubHomePath()}/${projectType}/${projectSlug}`;

export const getCertificatePath = (username: string, containerId: string) =>
  `${getProfilePath(username)}/certificates/${containerId}`;

export const getProfilePath = (username: string) => `/profiles/${username}`;

export const getProfileEditPath = (username: string) =>
  `${getProfilePath(username)}/edit`;

export const getMyProfilePath = () => getProfilePath('me');

export const getLoginPath = (params: { redirect?: string; error?: string }) =>
  getUrl('/login', params);

export const getRegisterPath = (params?: { redirect?: string }) =>
  getUrl('/register', params || {});

export const getTeamsQuote = () => '/business/teams-quote';

export const getTeamsCheckoutPath = (
  planType: 'directpurchase' | 'upgrade' | 'trial'
) => `/business/checkout?plan_type=${planType}`;

export const getSortingQuizPath = () => '/explore/sorting-quiz';

export const getAccountSettingsPath = () => '/account';

export const getGoalSettingsPath = () => '/account/goals_settings';

export const getEmailConfirmationPath = (resend?: 'resend') =>
  getUrl('/confirm', { resend: resend === 'resend' || undefined });

export const getDiscourseEmailConfirmationPath = () =>
  getUrl(getEmailConfirmationPath('resend'), { discourse: true });

export const getCircleStartHerePath = () =>
  'https://community.codecademy.com/c/start-here/';

export const normalizeDocsSlug = (slug: string) =>
  slug
    .split('/')
    .filter((string) => !['concepts', 'terms'].includes(string))
    .join('/');

export const getFullDocsBaseUrl = () => getBaseUrl() + '/resources/docs/';

export const getYoutubeVideoPath = (videoId: string) =>
  videoId && `https://www.youtube.com/watch?v=${videoId}`;

export const getVideosPath = () => `/resources/videos`;

export const getVideoPlaylistPath = (playlistSlug: string) =>
  `${getVideosPath()}/${playlistSlug}`;

export const getCodeChallengePath = (slug: string) =>
  `/code-challenges/${slug}`;

export const getVideoDetailPath = (playlistSlug: string, videoSlug: string) =>
  `${getVideoPlaylistPath(playlistSlug)}/${videoSlug}`;

export const getCareerJourneyCurriculumPath = ({
  careerJourneySlug,
}: {
  careerJourneySlug: string;
}) => `/career-journey/${careerJourneySlug}`;

export const getEnrolledPathSyllabusPath = (pathSlug: string) =>
  `/enrolled/paths/${pathSlug}`;

export const getBusinessPathLandingPagePath = (pathSlug: string) =>
  `/business/paths/${pathSlug}`;

export const getCareerJourneyTrackSyllabusPath = ({
  careerJourneySlug,
  pathSlug,
  trackSlug,
}: {
  careerJourneySlug: string;
  pathSlug: string;
  trackSlug: string;
}) =>
  `/career-journey/${careerJourneySlug}/path/${pathSlug}/track/${trackSlug}`;

interface SyllabusContentItemPathParams {
  journeySlug: string;
  pathSlug: string;
  trackSlug: string;
  moduleSlug: string;
  contentItemSlug: string;
  contentItemType: string;
}
const getContentItemTypeRoutePart = (contentItemType: string) =>
  contentItemType === 'quiz' ? 'quizzes' : `${contentItemType}s`;

export const getSyllabusContentItemPath = ({
  journeySlug,
  pathSlug,
  trackSlug,
  moduleSlug,
  contentItemSlug,
  contentItemType,
}: SyllabusContentItemPathParams) => {
  const contentItemTypeRoute = getContentItemTypeRoutePart(contentItemType);
  return `/journeys/${journeySlug}/paths/${pathSlug}/tracks/${trackSlug}/modules/${moduleSlug}/${contentItemTypeRoute}/${contentItemSlug}`;
};
export const getPracticePath = ({
  trackSlug,
  moduleSlug,
}: {
  trackSlug: string;
  moduleSlug: string;
}) => `/practice/tracks/${trackSlug}/modules/${moduleSlug}`;

export const getSmartPracticePath = ({
  slug,
  contentType,
}: {
  slug: string;
  contentType: ContentContainerEnum;
}) => `/smart-practice/${contentType}s/${slug}`;

export const getContentItemPath = (
  trackSlug: string,
  contentItem: ContentItem,
  queryParams?: Record<string, string | boolean>
) => {
  const type = contentItem.type
    ? getContentItemTypeRoutePart(contentItem.type)
    : '';
  const path = `/courses/${trackSlug}/${type}/${contentItem.slug}`;

  return queryParams ? getUrl(path, queryParams) : path;
};

export const getBootcampContentItemPath = (
  bootcampSlug: string,
  contentItem: ContentItem,
  queryParams?: Record<string, string | boolean>
) => {
  if (!contentItem.slug || !contentItem.hasContentItemAccess) return '';

  let path = '';

  switch (contentItem.type) {
    case 'project':
      path = `/projects/practice/${contentItem.slug}`;
      break;
    case 'replay':
      path = `/bootcamps/${bootcampSlug}/recordings/${contentItem.slug}`;
      break;

    case 'live_session':
      path = `/bootcamps/${bootcampSlug}/sessions/${contentItem.slug}`;
      break;

    default:
      path = `/bootcamps/${bootcampSlug}/${contentItem.type}s/${contentItem.slug}`;
      break;
  }

  return queryParams ? getUrl(path, queryParams) : path;
};

export const getOldCareerPathPath = (slug: string) => {
  return `/learn/paths/${CAREER_JOURNEY_TO_CAREER_PATH_MAP[slug]}`;
};

export const getEnrolledCoursePath = (slug: string) =>
  `/enrolled/courses/${slug}`;

export const getCourseLandingPagePath = (slug: string) => `/learn/${slug}`;

export const getPathLandingPagePath = (slug: string) => `/learn/paths/${slug}`;

export const getPricingPath = () => '/pricing';

export const getSectionAssessmentPath = ({
  journeySlug,
  pathSlug,
  partNumber,
}: {
  journeySlug: string;
  pathSlug: string;
  partNumber: string;
}) => {
  return `/exams/journeys/${journeySlug}/paths/${pathSlug}/parts/${partNumber}`;
};

export const getLandingPageBuilderPath = (slug: string) => `/pages/${slug}`;

export const getJobReadinessJobPath = (id: string) =>
  `/job-readiness/job/${id}`;

export const getWelcomeOnboarding = () => '/welcome/find-a-course';

export const getWelcomeOnboardingResults = () =>
  '/welcome/find-a-course/results';

export const getWorkspacesPath = (id: string) => `/users/${id}/workspaces`;

export const getBlogPath = (slug: string) =>
  `https://www.codecademy.com/resources/blog/${slug}`;

export const interviewSimulatorPath = () => '/interview-simulator';

export const interviewSimulatorChatPath = (params: URLSearchParams) =>
  `/interview-simulator/interview?${params}`;

export const interviewSimulatorFeedbackPath = (id: string) =>
  `/interview-simulator/interviews/${id}`;

export const studyGroupLeaderboardPath = () => '/learn?page=study_group';
