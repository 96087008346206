import { PartytownConfig } from '@builder.io/partytown/integration';

export const partytownConfig = (): PartytownConfig => ({
  forward: [
    'dataLayer.push', // for GTM
    'fbq', // for Facebook Pixel
    '_hsq.push', // for Hubspot
  ],
  lib: '/partytown/', // path for loading from static asset CMS
  loadScriptsOnMainThread: [
    /pepperjam/, // Pepperjam
    /snap/, // Snap Pixel
    /lightboxcdn/, // Digioh
    /linkedin/, // linkedin
    /debug/, // GTM debugging
  ],
  // This function runs in a worker and can't access other vars in this file.
  resolveUrl(url: URL, location: Location, type: string) {
    // Hoist GTM iframe out of Partytown so that GTM can create its own worker normally.
    if (url.hostname === 'www.googletagmanager.com' && type === 'iframe') {
      new BroadcastChannel('gtm-iframe').postMessage(url.href);
      return new URL('data:'); // blank url to prevent iframe within Partytown
    }

    /*
     * Proxy Facebook Pixel requests to resolve CORS issues
     * see https://partytown.builder.io/facebook-pixel#proxy-requests
     */
    if (url.hostname === 'connect.facebook.net') {
      return new URL(
        `partytown-fb${url.pathname}${url.search}`,
        location.origin
      );
    }

    /*
     * Proxy Reddit Pixel requests to resolve CORS issues
     */
    if (url.hostname === 'www.redditstatic.com') {
      return new URL(
        `partytown-reddit${url.pathname}${url.search}`,
        location.origin
      );
    }

    /*
     * Proxy Google Ads requests to resolve CORS issues
     */
    if (url.hostname === 'googleads.g.doubleclick.net') {
      let p = url.pathname;
      if (p.endsWith('/')) {
        // Google Ads returns a redirect if path has a trailing slash
        p = p.slice(0, -1);
      }
      return new URL(`partytown-googleads${p}${url.search}`, location.origin);
    }

    /*
     * Proxy Bing requests to resolve CORS issues
     */
    if (url.hostname === 'bat.bing.com') {
      return new URL(
        `partytown-bing${url.pathname}${url.search}`,
        location.origin
      );
    }

    /*
     * Proxy YouTube requests to resolve CORS issues
     */
    if (url.hostname === 'www.youtube.com') {
      return new URL(
        `partytown-youtube${url.pathname}${url.search}`,
        location.origin
      );
    }

    return url;
  },
});
