export enum FeatureFlag {
  AI_ASSISTANT_STATIC_PROMPTS = 'ai_assistant_static_prompts',
  AI_CUSTOM_PROJECT = 'ai_custom_project',
  AI_FREE_RESPONSE_QUESTION = 'ai_free_response_question',
  APPLE_SSO = 'apple_sso',
  BEHAVIORAL_INTERVIEW = 'behavioral_interview',
  COHORTS_INTERSTITIAL = 'cohorts_interstitial',
  CONCEPTS_IN_SKILLS = 'concepts_in_skills',
  CYCLE_E_TEAMS_REVAMP = 'cycle_e_teams_revamp',
  CYCLE_E_UNIFIED_REC_FLOW_V2 = 'cycle_e_unified_rec_flow_v2',
  DASHBOARD_SMART_PRACTICE = 'dashboard_smart_practice',
  ENTERPRISE_EMODULE_SYLLABUS = 'enterprise_emodule_syllabus',
  ENTERPRISE_GLOBAL_NAV = 'enterprise_global_nav',
  GOLD_EARLY_ACCESS = 'gold_early_access',
  MOCK_INTERVIEW_PREP = 'mock_interview_prep',
  PLATFORM_SHOWCASE = 'platform_showcase_feature',
  POST_QUIZ_REVIEW = 'ai_post_quiz_review',
  PRACTICE_EXPERIENCE = 'practice_xp',
  REFERRAL_ROCK_V1 = 'referral_rock_v1',
  RELEASE_EMODULE_PAGES = 'release_emodule_pages',
  RESPONSIVE_LE = 'responsive_le',
  STRIPE_AFTERPAY_CHECKOUT = 'stripe_afterpay_checkout',
  WEEKLY_SYLLABUS = 'weekly_syllabus',
  XP_LEADERBOARD_INTERSTITIAL = 'xp_leaderboard_interstitial',
}
