/*
 * NOTE: This is a local new variant of the SocialMediaSharing gamut component that should be ported to Gamut.
 * */
import { Alert, FlexBox, PopoverProps, Text } from '@codecademy/gamut';
import { LinkIcon, ShareIcon } from '@codecademy/gamut-icons';
import { useState } from 'react';

import { DropdownButton } from '../DropdownButton';
import {
  createShareLink,
  SOCIAL_SHARING_PLATFORMS,
} from '../SocialMediaSharing';

const clipboardAlertMessage = 'Link has been copied to clipboard.';

export type SocialMediaSharingDropdownProps = {
  url: string;
  message?: string;
  hashtags?: string[];
  mention?: string;
  action?: (e: React.MouseEvent, target: string) => void;
  defaultPosition?: PopoverProps['position'];
  addAlert?: (message: string) => void;
};

export const onClickWrapper = (
  e: React.MouseEvent,
  id: string,
  href: string,
  action?: SocialMediaSharingDropdownProps['action']
) => {
  e.preventDefault();
  action?.(e, `${id}_share`);
  const popupSize = 570;
  const left = (window.screen.width - popupSize) / 2;
  const top = (window.screen.height - popupSize) / 2;
  const features = `menubar=no,toolbar=no,status=no,width=${popupSize},height=${popupSize},top=${top},left=${left}`;
  window.open(href, '_blank', features)?.focus();
};

export const SocialMediaSharingDropdown: React.FC<
  SocialMediaSharingDropdownProps
> = ({
  url,
  message,
  hashtags,
  mention,
  action,
  defaultPosition = 'below',
  addAlert,
}) => {
  const [showClipboardAlert, setShowClipboardAlert] = useState(false);

  const copyUrlToClipboard = async (e: React.MouseEvent) => {
    action?.(e, `link_share`);
    await navigator.clipboard.writeText(url);

    if (addAlert) {
      addAlert(clipboardAlertMessage);
    } else {
      setShowClipboardAlert(true);
    }
  };

  return (
    <>
      <DropdownButton
        data-testid="social-share-dropdown"
        isMenu
        buttonType="text"
        defaultPosition={defaultPosition}
        px={0}
        dropdownItems={[
          ...SOCIAL_SHARING_PLATFORMS.map(
            ({ id, formatShare, baseUrl, displayName }) => ({
              id,
              text: displayName,
              clickHandler: (e: React.MouseEvent) => {
                onClickWrapper(
                  e,
                  id,
                  createShareLink(formatShare, baseUrl, {
                    url,
                    message,
                    hashtags,
                    mention,
                  }),
                  action
                );
              },
            })
          ),
          'separator',
          {
            id: 'copy-link',
            icon: LinkIcon,
            text: 'Copy link',
            ariaLabel: 'Copy link',
            clickHandler: (e) => copyUrlToClipboard(e),
          },
        ]}
      >
        <ShareIcon mr={4} size={22} />
        Share
      </DropdownButton>
      {showClipboardAlert && (
        <FlexBox
          minWidth="min-content"
          width={{ _: '80%', sm: '60%' }}
          position="fixed"
          top="6rem"
          left={{ _: '10%', sm: '20%' }}
          zIndex={999}
          data-testid="social-share-copy-to-clipboard-alert"
          justifyContent="center"
        >
          <Alert
            onClose={() => {
              setShowClipboardAlert(false);
            }}
            type="success"
            placement="inline"
          >
            <Text whiteSpace="break-spaces" display="inline">
              {clipboardAlertMessage}
            </Text>
          </Alert>
        </FlexBox>
      )}
    </>
  );
};
