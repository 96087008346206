import { getUrl } from './getUrl';

export const slugOrBlank = (slug: string | undefined) =>
  slug ? `/${slug}` : '';

export const getAssetPath = (path: string) =>
  `https://static-assets.codecademy.com/assets/${path}`;

export const getCatalogBasePath = () => '/catalog';

export const getCatalogPath = ({
  categoryType,
  category,
}: {
  categoryType: string;
  category?: string;
}) => `${getCatalogBasePath()}/${categoryType}${slugOrBlank(category)}`;

export const getLearnBasePath = () => '/learn';

export const getLearnPath = ({
  slug,
  isPath,
  isExtCourse,
  isExtPath,
}: {
  slug: string;
  isPath?: boolean;
  isExtCourse?: boolean;
  isExtPath?: boolean;
}) =>
  `${getLearnBasePath()}/${
    isPath
      ? 'paths/'
      : isExtCourse
      ? 'ext-courses/'
      : isExtPath
      ? 'ext-paths/'
      : ''
  }${slug}`;

// Entry path in the LE
export const getPathStartPath = (pathSlug: string) => {
  return `/paths/${pathSlug}`;
};

export const getEnrolledLearnPath = ({
  slug,
  isPath,
}: {
  slug: string;
  isPath?: boolean;
}) => `/enrolled/${isPath ? 'paths/' : 'courses/'}${slug}`;

export const getPathImageUrl = (pathSlug: string) =>
  `https://static-assets.codecademy.com/components/curriculum/path/${pathSlug}/curriculum-card.svg`;

export const getLearnerStoriesPath = () =>
  '/resources/blog/category/learner-stories/';

export const getExternalCoursePath = (slug: string) => `/ext-courses/${slug}`;

export const getExternalPathPath = (slug: string) => `/ext-paths/${slug}`;

export const getSmartPracticePath = ({
  slug,
  contentType,
  source = 'syllabus',
}: {
  slug: string;
  contentType: string;
  source?: string;
}) => `/smart-practice/${contentType}s/${slug}?source=${source}`;

export const getJobReadinessPath = () => '/job-readiness';

const proCheckoutPath = ({
  // TODO: Should we default to getting planId from a globally accessible value?
  // https://codecademy.atlassian.net/browse/GA-92
  planId = 'proGoldAnnualV2',
  redirectUrl = '',
  fromSignUpPage,
  discountCode,
  leftHandSide,
  skipOnboarding,
  periods,
  loggedInViaCheckout,
  planType,
}: ProCheckoutPathParams) =>
  getUrl(`/subscriptions/${planId}/checkout`, {
    ...(redirectUrl !== '' && { redirect_url: redirectUrl }),
    fromSignUpPage,
    skipOnboarding,
    periods,
    discountCode,
    lhs: leftHandSide,
    logged_in_via_checkout: loggedInViaCheckout,
    plan_type: planType,
  });

export enum ProCheckoutPathPlanType {
  Pro = 'pro',
  Plus = 'plus',
}

type ProCheckoutPathParams = {
  isTrial?: boolean;
  planId?: string;
  redirectUrl?: string;
  discountCode?: string;
  fromSignUpPage?: boolean;
  leftHandSide?: string;
  skipOnboarding?: boolean;
  periods?: string;
  loggedInViaCheckout?: boolean;
  planType?: ProCheckoutPathPlanType;
};

export const getProCheckoutPath = ({
  isTrial,
  ...params
}: ProCheckoutPathParams) =>
  proCheckoutPath({
    ...params,
    // TODO: Should we default to getting planId from a globally accessible value?
    // https://codecademy.atlassian.net/browse/GA-92
    // userAttributes.payments('defaultTrialPlanId'),
    ...(isTrial && { planId: params.planId ?? 'proGoldAnnualV2_7trial' }),
  });

export const getPortalCheckoutPath = ({
  planId,
  redirectUrl = '',
  isTrial,
  loggedInViaCheckout,
  ...params
}: ProCheckoutPathParams) =>
  getUrl(`/checkout`, {
    planId,
    ...(redirectUrl !== '' && { redirect_url: redirectUrl }),
    ...(isTrial && { planId: planId ?? 'proAnnualV4a_7trial' }),
    logged_in_via_checkout: loggedInViaCheckout,
    ...params,
  });

export const getTeamsDashboardPath = (id: string) =>
  `/business/account/${id}/teams`;

export const getCumulativeAssessmentPath = (
  pathSlug: string,
  trackSlug: string
) => `/cumulative-assessment/paths/${pathSlug}/tracks/${trackSlug}`;
