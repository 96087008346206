export const shouldShowForGeo = (
  userLocale: string,
  geoTarget: string | null | undefined
) => {
  switch (geoTarget) {
    case 'Global':
    case undefined:
    case null:
      return true;
    case 'Non-India Only':
      return userLocale !== 'IN';
    case 'India Only':
      return userLocale === 'IN';
    case 'US Only':
      return userLocale === 'US';
    default:
      return false;
  }
};
