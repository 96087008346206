function hiddenFrame(doc: Document) {
  const f = doc.createElement('iframe');
  f.setAttribute('height', '0');
  f.setAttribute('width', '0');
  f.setAttribute('style', 'display: none; visibility: hidden;');
  doc.body.appendChild(f);
  return f;
}

// Double frame is what GTM would normally use without Partytown.
export function gtmDoubleFrame(src: string) {
  const outerFrame = hiddenFrame(document);
  const innerFrame = hiddenFrame(outerFrame.contentWindow!.document);
  innerFrame.src = src;
}
